<template>
    <div class="sex-age">
      <div class="sex-age-sex">
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/icon_ry.png" />
          <div>人员总数</div>
          <div>
            <div>{{ info.totalNum }}</div>
            <div>人</div>
          </div>
        </div>
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/icon_grcqrs.png" />
          <div>工人出勤人数</div>
          <div>
            <div>{{ info.attendanceWork }}</div>
            <div>人</div>
          </div>
        </div>
      </div>
      <div class="sex-age-sex">
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/ico_xjcry.png" />
          <div>新进场人员</div>
          <div>
            <div>{{ info.newPersonNum }}</div>
            <div>人</div>
          </div>
        </div>
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/icon_cqzrs.png" />
          <div>出勤总人数</div>
          <div>
            <div>{{ info.totalAttNum }}</div>
            <div>人</div>
          </div>
        </div>
      </div>
      <div class="sex-age-sex">
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/icon_xcry.png" />
          <div>现场人员</div>
          <div>
            <div>{{ info.personnelPresent }}</div>
            <div>人</div>
          </div>
        </div>
        <div class="sex-age-sex-item">
          <img src="../../../assets/icons/icon_cqlv.png" />
          <div>出勤率</div>
          <div>
            <div>{{ info.attendance }}</div>
            <div>%</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      info:{
        type:Object,
        default:()=>{
          return {}
        }
      }
    },
  }
  </script>
  
  <style lang="less" scoped>
  .sex-age {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    &-title {
      padding: 10px 0 20px 0;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
    }
    &-sex {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.1);
      &-item {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0 20px;
        height: 48px;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        &:first-child{
          border-left: none;
        }
        & > img {
          width: 18px;
          height: 18px;
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #ffffff;
          line-height: 21px;
          width: 100%;
          margin-left: 15px;
        }
        & > div:nth-child(3) {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          & > div:nth-child(1) {
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
          }
          & > div:nth-child(2) {
            font-size: 14px;
            color: #ffffff;
            line-height: 21px;
            margin-left: 5px;
          }
        }
      }
      & + & {
        margin-top: 10px;
      }
    }
  }
  </style>
  