<template>
  <div class="zy-layout">
    <!-- 左边布局 -->
    <div class="zy-layout-column float-left page-animation-left">
      <zy-title>项目信息</zy-title>
      <descriptions
        style="margin-top: 10px"
        :list="proInfoList"
      ></descriptions>
      <zy-title class="jxyh-margin">项目目标</zy-title>
      <descriptions
        style="margin-top: 10px"
        :list="proTargetList"
      ></descriptions>
      <zy-title class="jxyh-margin">项目工期</zy-title>
      <div
        class="jxyh-flex-v"
        style="padding: 10px 0 10px 10px"
      >
        <div class="gif-pie">
          <el-image
            :src="analysis"
            alt="gif"
          ></el-image>
          <div class="position">
            <div style="font-weight: bold">{{ otherProjectInfo.projectPercentage }}</div>
            <div style="font-size: 12px">工期进度</div>
          </div>
        </div>
        <div>
          <shade-box :info="otherProjectInfo"></shade-box>
        </div>
      </div>
      <zy-title
        class="jxyh-margin"
        style="margin-bottom: 10px"
        >双碳管理</zy-title
      >
      <carbon :info="otherProjectInfo"></carbon>
    </div>
    <!-- 中间布局 -->
    <div class="zy-layout-column float-center">
      <zy-carousel-box :list="otherProjectInfo.milestonesList">
        <template #customSlot="scope">
          <div style="color: #66bbf9">{{ scope.row.startDate }}</div>
        </template>
      </zy-carousel-box>
    </div>
    <!-- 右边布局 -->
    <div class="zy-layout-column float-right page-animation-right">
      <zy-title>人员概况</zy-title>
      <person :info="personData"></person>
      <zy-title class="jxyh-margin">安全管理</zy-title>
      <zy-canvas-ring
        style="margin-top: 10px"
        :list="ringInfo.safetyList"
        :size="100"
        :height="190"
      >
        <div style="font-size: 20px; font-weight: bold">{{ ringInfo.potentialTotal }}</div>
        <div style="font-size: 12px">安全隐患(个)</div>
      </zy-canvas-ring>
      <zy-title style="margin-top: 30px">质量管理</zy-title>
      <zy-canvas-ring
        style="margin-top: 10px"
        :list="ringInfo.qualityList"
        :size="100"
        :height="190"
      >
        <div style="font-size: 20px; font-weight: bold">{{ ringInfo.qualityTotal }}</div>
        <div style="font-size: 12px">质量隐患(个)</div>
      </zy-canvas-ring>
      <zy-title style="margin-top: 30px">信息播报</zy-title>
      <vue-seamless-scroll
        style="height: 100px; overflow: hidden"
        v-if="noticeList && noticeList.length"
        :data="noticeList"
        :class-option="classOption"
      >
        <ul>
          <li
            class="message-box"
            v-for="(item, index) in noticeList"
            :key="index"
            @click="openNotice(item)"
          >
            <div class="message-box-left"></div>
            <div class="message-box-right">
              <div class="message-box-right-text">
                <span>{{ item.releaseDate }}</span>
                <span class="overText">{{ item.contentTitle }}</span>
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <zy-dialog
        :visible="visible"
        :title="'信息播报'"
        @close="closeDialog"
      >
        <div class="content">
          <p class="content-title">{{ noticeDetail.contentTitle }}</p>
          <div class="content-date">{{ noticeDetail.releaseDate }}</div>
          <div
            class="content-detail"
            v-html="noticeDetail.content"
          ></div>
        </div>
      </zy-dialog>
    </div>
  </div>
</template>

<script>
import components from "@/view/jxyh/components/index.js"
import Descriptions from "./components/descriptions.vue"
import ShadeBox from "./components/shade-box.vue"
import Carbon from "./components/carbon.vue"
import Person from "./components/person.vue"
import analysis from "@/view/jxyh/assets/images/analysis.gif"
import { getProjectTarget, getPersonData, getCheSecure, getNoticeList, getNoticeDetail } from "@/jxyh/api/centerManger"
export default {
  ...components(["ZyLayout", "ZyTitle", "ZyCarouselBox", "ZyCanvasRing", "ZyDialog"], { Descriptions, ShadeBox, Carbon, Person }),
  data() {
    return {
      analysis,
      proInfoLabel: ["项目名称", "项目地点", "建筑面积", "建设单位", "监理单位", "设计单位", "总包单位"],
      proInfoProp: ["name", "address", "buildArea", "buildOrg", "supervisorOrg", "designOrg", "contractOrg"],
      proTargetLabel: ["绿色建筑", "智慧建筑", "装配式建筑", "质量", "安全"],
      proTargetProp: ["greenBuilding", "smartBuilding", "purposeBuilding", "quality", "safe"],
      otherProjectInfo: {},
      personData: {},
      ringInfo: {},
      noticeList: [],
      visible: false,
      noticeDetail: {},
    }
  },
  computed: {
    proInfoList() {
      return this.proInfoLabel.map((item, index) => {
        if (item == "项目地点") {
          let info = this.$store.state.info
          return {
            label: item,
            value: info.provinceName + info.cityName + info.countiesName + info.address,
          }
        } else {
          return {
            label: item,
            value: this.$store.state.info[this.proInfoProp[index]],
          }
        }
      })
    },
    proTargetList() {
      return this.proTargetLabel.map((item, index) => {
        return {
          label: item,
          value: this.otherProjectInfo[this.proTargetProp[index]],
        }
      })
    },
    classOption() {
      return {
        singleHeight: 50,
        limitMoveNum: 2,
      }
    },
  },
  mounted() {
    this.getOtherProjectInfo()
    this.getPersonInfo()
  },
  methods: {
    getOtherProjectInfo() {
      getProjectTarget().then((res) => {
        this.otherProjectInfo = res.data
      })
    },
    getPersonInfo() {
      getPersonData().then((res) => {
        this.personData = res.data
      })
      getCheSecure().then((res) => {
        this.ringInfo = res.data
        this.ringInfo.safetyList = [
          {
            name: "已闭合",
            value: this.ringInfo.soldItemNum,
            rate: this.ringInfo.soldItemRate,
            unit: "个",
          },
          {
            name: "未闭合",
            value: this.ringInfo.notSoldItemNum,
            rate: this.ringInfo.notSoldItemRate,
            unit: "个",
          },
        ]
        this.ringInfo.qualityList = [
          {
            name: "已闭合",
            value: this.ringInfo.qualityItemNum,
            rate: this.ringInfo.qualityItemRate,
            unit: "个",
          },
          {
            name: "未闭合",
            value: this.ringInfo.notQualityItemNum,
            rate: this.ringInfo.notQualityItemRate,
            unit: "个",
          },
        ]
      })
      getNoticeList({ screen: 1, noticeStatus: 0, pageSize: 10, pageNum: 1 }).then((res) => {
        this.noticeList = res.data.page.list
      })
    },
    openNotice(item) {
      getNoticeDetail({ id: item.id }).then((res) => {
        this.noticeDetail = res.data
        if (this.noticeDetail.content) {
          this.visible = true
        } else {
          this.$message.info("本条公告无详情内容")
        }
      })
    },
    closeDialog() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
@import url("../../style/animation.less");
.zy-layout {
  font-family: SourceHanSansCN;
  user-select: none;
  width: 100%;
  height: 100%;
  color: #ffffff;
  .back {
    width: 100%;
    height: 100%;
  }
  .float-left {
    position: absolute;
    left: 20px;
    top: 100px;
    width: 534px;
  }
  .float-right {
    position: absolute;
    right: 20px;
    top: 100px;
    width: 534px;
  }
  .float-center {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .gif-pie {
    width: 160px;
    margin-right: 26px;
    position: relative;
    .position {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  .message-box {
    display: flex;
    margin-top: 10px;
    cursor: pointer;
    &-left {
      width: 4px;
      height: 40px;
      background: #19fbff;
    }
    &-right {
      width: 531px;
      height: 40px;
      background: linear-gradient(277deg, rgba(16, 31, 68, 0) 0%, rgba(46, 205, 235, 0.2) 100%);
      &-text {
        display: flex;
        font-size: 14px;
        line-height: 40px;
        margin: 0 16px;
        .overText {
          flex: 1;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          white-space: nowrap;
          margin-left: 20px;
        }
      }
    }
  }
}
.content {
  &-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
  }
  &-date {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff20;
  }
  &-detail {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
  }
}
</style>
<style>
.content-detail p > img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
