<template>
  <el-descriptions
    class="margin-top"
    :column="column"
    :size="size"
    border
  >
    <el-descriptions-item
      v-for="(item, index) in list"
      :key="index"
    >
      <template slot="label">{{ item.label }}</template>
      <div class="hdd">
        <p
          style="white-space: nowrap"
          :class="{ gundong: item.value?.length > 30 }"
        >
          {{ item.value ? item.value : "——" }}
        </p>
      </div>
    </el-descriptions-item>
  </el-descriptions>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    column: {
      type: Number,
      default: 1,
    },
    size: {
      type: String,
      default: "small",
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .el-descriptions-row {
  display: flex;
  height: 36px;
  width: 534px;
  .el-descriptions-item__cell {
    border: 1px solid rgba(255, 255, 255, 0.1);
    &.el-descriptions-item__content {
      padding: 7px 20px;
      width: calc(100% - 100px);
    }
  }
  .el-descriptions-item__cell.el-descriptions-item__label {
    display: inline-block;
    width: 100px;
    height: 36px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 20px;
    font-size: 14px;
    background: #1e5087;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .el-descriptions-item__content {
    .hdd {
      margin: 0 auto;
      overflow: hidden;
      font-size: 14px;
    }

    .gundong {
      font-size: 14px;
      display: inline-block;
      white-space: nowrap;
      will-change: transform;
      -webkit-backface-visibility: hidden;
      animation: 10s wordsLoop linear infinite normal;
    }

    .gundong:hover {
      /* 设置动画是否暂停 paused暂停*/
      animation: 10s wordsLoop linear infinite paused;
    }

    @keyframes wordsLoop {
      0% {
        transform: translateX(50px);
        -webkit-transform: translateX(50px);
      }

      100% {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }
    }

    @-webkit-keyframes wordsLoop {
      0% {
        transform: translateX(50px);
        -webkit-transform: translateX(50px);
      }

      100% {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }
    }
  }
}
/deep/ .el-descriptions__body {
  color: #ffffff;
  background: transparent;
}
</style>
