import { get } from "@/utils/request"

// 获取项目目标
export function getProjectTarget() {
  return get("/pms/view/projectObjectives")
}

// 获取人员概况
export function getPersonData() {
  return get("/crm/attendance/getPersonData")
}

// 获取安全\质量管理
export function getCheSecure() {
  return get("/pr/getCheSecure")
}

// 获取信息播报
export function getNoticeList(params) {
  return get("/pms/notice/list", params)
}

// 获取公告详情
export function getNoticeDetail(params) {
  return get("/pms/notice/detail", params)
}
