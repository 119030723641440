<template>
  <div class="jxyh-flex-v" style="justify-content: space-between;">
    <div class="jxyh-flex-v">
      <el-image :src="icon_ystg" alt="云" class="img"></el-image>
      <div class="box">
        <div class="top-text">
          <span class="num">{{ info.cumulativelyCo }}</span>
          <span>%</span>
        </div>
        <div class="blue-text">累计降低二氧化碳排放量</div>
      </div>
    </div>
    <div class="jxyh-flex-v">
      <el-image :src="icon_kctg" alt="晴" class="img"></el-image>
      <div class="box">
        <div class="top-text">
          <span class="num">{{ info.solarElectricity }}</span>
          <span>kwh</span>
        </div>
        <div class="blue-text">太阳能发电节约电量</div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_ystg from '@/view/jxyh/assets/icons/icon_ystg.png'
import icon_kctg from '@/view/jxyh/assets/icons/icon_kctg.png'

export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      icon_ystg,
      icon_kctg
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  width: 64px;
  height: 64px;
  margin-right: 20px;
}

.box {
  font-size: 14px;

  .top-text {
    color: #ffffff;

    .num {
      font-weight: bold;
      font-size: 26px;
      line-height: 33px;
      margin-right: 7px;
    }
  }

  .blue-text {
    color: #66BBF9;
    line-height: 26px;
  }
}
</style>