<template>
  <div>
    <div class="green-line"></div>
    <div class="shade-box">
      <div class="green shade-box-content">安全生产天数</div>
      <div class="green">
        <span class="max-text">{{ info.safeProductionDays }}</span>
        <span>天</span>
      </div>
      <div class="shade-box-content">起始日期：{{ formatDate(info.projectDuration) }}</div>
    </div>
    <div class="jxyh-flex-v" style="margin-top: 9px">
      <div class="border-box jxyh-flex-h">
        <div class="border-box-content">施工天数</div>
        <div class="border-box-content">
          <span class="max-text">{{ info.constructionDays }}</span>
          <span>天</span>
        </div>
      </div>
      <div class="border-box jxyh-flex-h">
        <div class="border-box-content">总工期</div>
        <div class="border-box-content">
          <span class="max-text">{{ info.totalProjectDays }}</span>
          <span>天</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    formatDate(date) {
      if(date){
        let arr = date.split('-')
        return arr[0] + '年' + arr[1] + '月' + arr[2] + '日'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.green-line {
  width: 100%;
  height: 2px;
  background: #63e881;
}
.shade-box {
  width: 338px;
  height: 92px;
  background: linear-gradient(180deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.2) 100%);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(24, 123, 205, 0), rgba(51, 216, 250, 0.4)) 1 1;
  .green {
    font-size: 14px;
    color: #63e881;
    text-align: center;
  }
  &-content {
    font-size: 14px;
    line-height: 34px;
    text-align: center;
  }
  .max-text {
    font-size: 26px;
    letter-spacing: 8px;
    font-weight: bold;
  }
}
.border-box {
  width: 169px;
  height: 63px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  text-align: center;
  &:first-child{
    border-right: none;
  }
  &-content {
    line-height: 24px;
    text-align: center;
    .max-text {
      font-size: 20px;
      // letter-spacing: 8px;
      font-weight: bold;
    }
  }
}
</style>